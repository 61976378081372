import React from 'react';
import Layout from '../../components/Layout/layout';
import SEO from '../../components/seo';
import ThankYou from '../../components/ConversionPath/demo-requested';
// import Image from '../../components/image';
import TestmonialReviews from '../../components/TestimonialReviews/TestmonialReviews';
import { restaurantTestimonials } from '../../data/restaurant-data';

export default function ThankYouPage() {
  return (
    <Layout isLanding>
      <SEO title="Thank you - your demo request has been received | SpotOn " />

      <ThankYou phoneNumber="" phoneText="" thankYouSubheader="" />

      <TestmonialReviews sectionData={restaurantTestimonials} />
    </Layout>
  );
}
